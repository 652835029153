import type {
  Entity,
  NewEntity,
} from '@/features/core/entity-repository/entity';
import { RestorableEntity } from '@/features/core/entity-repository/entity';
import type {
  StorageSetupMeta,
  StorageSetupStrategy,
} from '@/features/core/storage';
import { DBVersions } from '@/features/core/storage/dexie/versions';

export class NewImageCachingQueue
  extends RestorableEntity
  implements NewEntity
{
  type = 'imageCaching';
}

export class ImageCachingQueue extends NewImageCachingQueue implements Entity {
  id!: string;
  url!: string;
}

export class ImageCachingQueueStorageSetup implements StorageSetupStrategy {
  setup({ version, storeName }: StorageSetupMeta): Promise<void> {
    version(DBVersions.ImageCachingQueue_1).stores({
      [`${storeName}`]: '&id,url',
    });

    return Promise.resolve();
  }
}
