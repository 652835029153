import type {
  Entity,
  NewEntity,
} from '@/features/core/entity-repository/entity';
import { RestorableEntity } from '@/features/core/entity-repository/entity';
import type {
  StorageSetupMeta,
  StorageSetupStrategy,
} from '@/features/core/storage';
import type { Merchant } from '@/features/merchant';
import { DBVersions } from '@/features/core/storage/dexie/versions';

export class NewUser extends RestorableEntity implements NewEntity {
  type = 'user';
}

export enum USER_ROLE {
  PICKER = 'picker',
}

export class User extends NewUser implements Entity {
  id!: string;
  merchant!: Merchant;
  email!: string;
  roles!: USER_ROLE[];
  lastActivity!: string;
}

export class UserStorageSetup implements StorageSetupStrategy {
  setup({ version, storeName }: StorageSetupMeta): Promise<void> {
    version(DBVersions.User_1).stores({
      [`${storeName}`]:
        '&id,merchantReference,merchantName,merchantAddress,email,role',
    });
    version(DBVersions.User_2).stores({
      [`${storeName}`]:
        '&id,merchantReference,merchantName,merchantAddress,email,role,merchant',
    });
    version(DBVersions.User_3).stores({
      [`${storeName}`]: '&id,email,roles,merchant',
    });

    return Promise.resolve();
  }
}
