import type { EntityType } from '@/features/core/entity-repository';
import type {
  Client,
  ExtendableEvent,
} from '@firebase/messaging/dist/src/util/sw-types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface EntityUpdate<T extends EntityType<any>> {
  entity: T;
  ids?: string[];
  updated?: boolean;
  removed?: boolean;
  notifyAboutCheckin?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
}

export interface AppServiceWorker {
  showNotification: (title: string, options?: NotificationOptions) => void;

  notifySync(instanceId: string): Promise<void>;

  registerPeriodicSync(tag: string, period: number): Promise<void>;
}

export enum ServiceWorkerEventTypes {
  Push = 'push',
  Sync = 'sync',
  Message = 'message',
  SyncScheduler = 'syncScheduler',
  UpdateEntity = 'updateEntity',
  PeriodicSync = 'periodicsync',
  CheckinNotify = 'checkinNotify',
  SameDateCustomerNotify = 'sameDateCustomerNotify',
  EventBus = 'eventBus',
  SetAppVersion = 'setAppVersion',
}

export enum PushActions {
  Get = 'get',
  Delete = 'delete',
}

interface SyncManager {
  getTags(): Promise<string[]>;

  register(tag: string): Promise<void>;
}

interface PeriodicSyncRegisterOptions {
  minInterval?: number | string;
}

interface PeriodicSyncManager {
  getTags(): Promise<string[]>;

  register(tag: string, options?: PeriodicSyncRegisterOptions): Promise<void>;
}

declare global {
  interface ServiceWorkerRegistration {
    readonly sync: SyncManager;
    readonly periodicSync: PeriodicSyncManager;
  }

  interface SyncEvent extends ExtendableEvent {
    readonly lastChance: boolean;
    readonly tag: string;
  }

  interface ServiceWorkerGlobalScopeEventMap {
    sync: SyncEvent;
    periodicsync: SyncEvent;
  }
}

export interface Options {
  skipSW: boolean;
}

export type AdoptedServiceWorker = Pick<
  ServiceWorkerContainer,
  'addEventListener' | 'ready'
>;

export interface MessageEvent extends ExtendableEvent, SyncEvent {
  data: ServiceWorkerMessageData | unknown;
  source: Client | unknown;
}

export interface ServiceWorkerMessageData {
  type: string;
  version?: string;
}
