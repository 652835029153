import type {
  Entity,
  NewEntity,
} from '@/features/core/entity-repository/entity';
import { RestorableEntity } from '@/features/core/entity-repository/entity';
import type {
  BooleanNumber,
  StorageSetupMeta,
  StorageSetupStrategy,
} from '@/features/core/storage';
import { DBVersions } from '@/features/core/storage/dexie/versions';
import type { EntitySyncData } from '@/features/sync-scheduler/types';

export class NewSync extends RestorableEntity implements NewEntity {
  type = 'sync';
}

export class Sync extends NewSync implements Entity {
  id!: string;
  prevId?: string;
  data!: EntitySyncData;
  scheduledAt!: Date;
  completedAt?: Date;
  isCompleted!: BooleanNumber;
  retries!: number;
  failReason?: string;
  reportSent?: BooleanNumber;
}

export class SyncStorageSetup implements StorageSetupStrategy {
  setup({ version, storeName }: StorageSetupMeta): Promise<void> {
    version(DBVersions.Sync_1).stores({
      [storeName]:
        '&id' +
        ',prevId' +
        ',data.entity' +
        ',data.entityId' +
        ',scheduledAt' +
        ',completedAt' +
        ',isCompleted' +
        ',retries',
    });

    version(DBVersions.Sync_2).stores({
      [storeName]:
        '&id' +
        ',prevId' +
        ',data.entity' +
        ',data.entityId' +
        ',scheduledAt' +
        ',completedAt' +
        ',isCompleted' +
        ',retries' +
        ',reportSent',
    });

    return Promise.resolve();
  }
}
