import type {
  Entity,
  NewEntity,
} from '@/features/core/entity-repository/entity';
import { RestorableEntity } from '@/features/core/entity-repository/entity';
import type {
  StorageSetupMeta,
  StorageSetupStrategy,
} from '@/features/core/storage';
import { DBVersions } from '@/features/core/storage/dexie/versions';

export class NewOrderMetadata extends RestorableEntity implements NewEntity {
  type = 'orderMetadata';
}

export class OrderMetadata extends NewOrderMetadata implements Entity {
  id!: string;
  seen!: boolean;
  showAsUnseenUntil?: Date;
}

export class OrderMetadataStorageSetup implements StorageSetupStrategy {
  setup({ version, storeName }: StorageSetupMeta): Promise<void> {
    version(DBVersions.OrderMetadata_1).stores({
      [storeName]: '&id,seen,showAsUnseenUntil',
    });

    return Promise.resolve();
  }
}
