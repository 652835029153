import type { PipelinePlugin } from '@ads/plugin-pipeline';
import type { RouteLocationNamedRaw } from 'vue-router';
import type { OrderPluginDto } from '../types';

export class SetTargetRoutePlugin implements PipelinePlugin<OrderPluginDto> {
  constructor(private targetRoute: RouteLocationNamedRaw) {}
  public execute(dataTransferObject: OrderPluginDto): OrderPluginDto {
    dataTransferObject.targetRoute = this.targetRoute;
    return dataTransferObject;
  }
}
