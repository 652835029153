import { notify } from '@kyvg/vue3-notification';
import type { Cancellable } from '@/utils/types';
import type { Ref } from 'vue';
import { ref } from 'vue';

export interface NotificationService {
  isShowMessage: Ref<boolean>;
  show(options: NotificationOptions): Cancellable;
  showMessage(): void;
  hideMessage(): void;
}

export interface NotificationOptions {
  text: string;
  /** Class that will be assigned to the notification */
  type?: NotificationType;
  /** Time (in ms) to keep the notification on screen */
  duration?: number;
  /** Time (in ms) of notification animation speed */
  speed?: number;
  /** Additional data that needs to be passed */
  data?: Record<string, unknown>;
  /** Specifying whether the user should be notified after a new notification replaces an old one. */
  renotify?: boolean;
}

export enum NotificationType {
  Info = 'info',
  Success = 'success',
  Error = 'error',
}

export interface CancellableNotification extends Cancellable {
  id: number;
}

export class NotifyNotificationService implements NotificationService {
  private id = 0;
  public static readonly notificationDefaultDuration = 5000;
  public isShowMessage = ref(false);

  show(options: NotificationOptions): CancellableNotification {
    // Store the ID instead of referencing the class property
    // Allows closing this specific notification later via `cancel`
    const id = this.getNotificationId();
    notify({
      id,
      text: options.text,
      speed: options.speed,
      type: options.type ?? NotificationType.Info,
      duration:
        options.duration ??
        NotifyNotificationService.notificationDefaultDuration,
      data: options.data ?? {},
    });
    return {
      id,
      cancel: () => {
        notify.close(id);
      },
    };
  }

  showMessage(): void {
    this.isShowMessage.value = true;
  }

  hideMessage(): void {
    this.isShowMessage.value = false;
  }

  /**
   * Returns an id for the notification and increments the internal counter
   */
  private getNotificationId() {
    return this.id++;
  }
}
