import { $t } from '@/i18n';
import type { LogLevel } from '@/features/core/logger';
import type { ApiClientError } from '@/features/core/api/types';

declare module '@/features/core/errors/error-code' {
  interface ErrorCodes {
    [UnknownError.Code]: 'UnknownError';
    [HttpGeneratedError.Code]: 'HttpGeneratedError';
  }
}

export interface ErrorContext {
  [key: string]: unknown;
  TechnicalErrorID?: string;
}

export interface UnknownErrorContext extends ErrorContext {
  ErrorReason: string;
}

interface ErrorWithMessage {
  message: string;
}

export class BaseError<C extends ErrorContext = ErrorContext> extends Error {
  public readonly originalError?: unknown;
  static Code = 0;
  static Message = 'BaseError';
  static LogLevel: LogLevel = 50;
  handled = false;
  ctor = this.constructor as typeof BaseError;

  constructor(protected context?: C) {
    super();

    this.ctor = this.constructor as typeof BaseError;
    this.generateMessage();
  }
  protected generateMessage(): void {
    this.context = {
      ...this.context,
      TechnicalErrorID: this.getTechnicalErrorID(),
    } as C;
    this.message = Object.keys(this.context).reduce((message, key) => {
      if (this.context)
        return message.replace(`{${key}}`, String(this.context[key]));
      return '';
    }, this.ctor.Message);
  }

  getTechnicalErrorID(): string {
    return String(this.ctor.Code).padStart(4, '0');
  }

  getCode(): number {
    return this.ctor.Code;
  }

  getOriginalMessage(): string {
    return this.ctor.Message;
  }

  getContext(): C | undefined {
    return this.context;
  }

  getLogLevel(): LogLevel {
    return this.ctor.LogLevel;
  }
}

export class SomethingWrongError extends BaseError {
  static Code = 1;
  static Message = $t('errors.something-went-wrong.text');
}

export class HttpGeneratedError extends SomethingWrongError {
  static Code = 2 as const;
  constructor(
    private errorCode: number | undefined,
    public readonly originalError: ApiClientError,
  ) {
    super();
    this.generateMessage();
  }

  getTechnicalErrorID(): string {
    const technicalErrorID = super.getTechnicalErrorID();
    return this.errorCode
      ? `${technicalErrorID}:${this.errorCode}`
      : technicalErrorID;
  }
}

export class UnknownError extends BaseError<UnknownErrorContext> {
  static Code = 3 as const;
  static Message = $t('errors.unknown-error.text');
}

export const isErrorWithMessage = (
  error: unknown,
): error is ErrorWithMessage => {
  return (error as ErrorWithMessage).message !== undefined;
};

export class HandledError<T = unknown> extends Error {
  constructor(public readonly originalError: T) {
    super();
  }
}
