import type { Order } from '@/features/orders/entities/order';
import type { USER_ROLE, User } from '@/features/user/entities';
import { $t } from '@/i18n';
import type { RouteLocationNormalized } from 'vue-router';

export interface AgeVerification {
  status: Status;
  dateOfBirth: Date | null;
  userIdType: string | null;
  rejectionReason: RejectionReason | null;
  requiredAge: number;
}

export enum Status {
  Verified = 'verified',
  Rejected = 'rejected',
  NotVerified = 'not verified',
}

export enum RejectionReason {
  Underage = 'Underage',
  NoId = 'No ID Provided',
}

export const idTypeList = [
  {
    value: 'licence',
    label: $t('components.age-verification.id-type-list.license.label'),
    e2eLabel: 'licence',
  },
  {
    value: 'passport',
    label: $t('components.age-verification.id-type-list.passport.label'),
    e2eLabel: 'passport',
  },
  {
    value: 'id-card',
    label: $t('components.age-verification.id-type-list.id-card.label'),
    e2eLabel: 'id-card',
  },
];

export type AgeVerificationDTO = {
  order: Order;
  isVerified: boolean;
  ageVerification: Omit<AgeVerification, 'requiredAge' | 'status'>;
  itemRejectionReasonRequired: boolean;
};

export type AgeVerificationOnHandoverDTO = {
  order: Order;
  redirect: null | string;
  redirectTo: RouteLocationNormalized;
  isVerificationRequired: boolean;
  user: User | undefined;
  userRoles: USER_ROLE[] | undefined;
};

export const ITEM_REJECTION_REASON =
  'age-verification-failed:age-verification-failed';
