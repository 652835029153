import type { RouteLocationNormalized } from 'vue-router';

export const shouldLogRouteChange = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
): boolean => {
  if (to.path === from.path) {
    return false;
  }

  if (from.meta?.skipRouteLogs || to.meta?.skipRouteLogs) {
    return false;
  }

  return true;
};
