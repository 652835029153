import type { App } from 'vue';
import type { VuePlugin } from './plugin';
import { resolvePluginOptions } from './plugin';

export type ExecPluginFunction<O> = (
  app: App,
  options: O,
) => void | Promise<void>;

export class ExecPluginFactory {
  static fn<O>(pluginFn: ExecPluginFunction<O>): ExecPlugin<O> {
    return new ExecPlugin(pluginFn);
  }
}

export class ExecPlugin<O> implements VuePlugin {
  constructor(private pluginFn: ExecPluginFunction<O>) {}

  install(app: App, options: O | (() => O)): void {
    const opts: O = resolvePluginOptions(options);

    void this.pluginFn(app, opts);
  }
}
