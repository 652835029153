import { ExecPluginFactory, MultiPluginFactory } from '@/features/core/plugin';
import { syncSchedulerServicePlugin } from '@/features/sync-scheduler';
import { ordersApiPlugin, ordersApiRegistryPlugin } from './api';
import { ordersEntityPlugin } from './entities';
import { ordersServicePlugin } from './services';
import { orderCheckinNotifyPlugin } from './checkin';

export const ordersExecutePlugin = ExecPluginFactory.fn(() => {
  const orderService = ordersServicePlugin.get();
  syncSchedulerServicePlugin
    .get()
    .onScheduleDone(orderService.orderScheduleCallback.bind(orderService));
});

export const ordersPlugin = MultiPluginFactory.with({
  ordersApiPlugin,
  ordersApiRegistryPlugin,
  ordersEntityPlugin,
  ordersServicePlugin,
  orderCheckinNotifyPlugin,
  ordersExecutePlugin,
});
