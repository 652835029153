import {
  ExecPluginFactory,
  MultiPluginFactory,
  ProviderPluginFactory,
} from '@/features/core/plugin';
import { PerformanceTrackerService } from './services/performance-tracker';

export const performanceTrackerServicePlugin =
  ProviderPluginFactory.create<PerformanceTrackerService>({
    key: Symbol('PerformanceTrackerService'),
    defaultFactory: {
      create: () => new PerformanceTrackerService(),
    },
  });

export const performanceTrackerExecutePlugin = ExecPluginFactory.fn(() => {
  /*
   * This is a temporarily disabled feature
   * @see https://aldi-sued.atlassian.net/browse/CICS-61394
   */
  // const { clearOldTrackingData } = usePerformanceTracker();
  // setInterval(() => {
  //   clearOldTrackingData();
  // }, 1000 * 60);
});

export const performanceTrackerPlugin = MultiPluginFactory.with({
  performanceTrackerServicePlugin,
  performanceTrackerExecutePlugin,
});
